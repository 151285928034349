<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      head-title="安全活动统计报表"
      @head-save="headSave()"
    ></head-layout>
    <el-scrollbar>
      <div>
        <el-main>
          <el-form :model="dataForm" :rules="rules" ref="formLayout" label-width="190px"
                   class="demo-ruleForm form-container"
                   style="width: 99%;">
            <head-layout
              head-title="基本信息"
            ></head-layout>
            <el-row class="custom-row" :gutter="20" style="margin-top: 20px">
              <el-col :span="12">
                <el-form-item label="企业名称" prop="companyName">
                  <template slot="label">
                    <span>企业名称</span>
                  </template>
                  <el-input
                    disabled="true"
                    v-model="dataForm.companyName"
                    controls-position="right"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="统计月份" prop="dateAndTime">
                  <el-date-picker
                    v-model="dataForm.dateAndTime"
                    type="month"
                    format="yyyy-MM"
                    value-format="yyyy-MM"
                    :disabled="true"
                    @change="chooseDate"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="填报日期" prop="updateTime">
                  <template slot="label">
                    <span>填报日期:</span>
                  </template>
                  <el-date-picker
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    v-model="dataForm.updateTime"
                    type="date"
                    disabled="true"
                    placeholder="保存后自动生成"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="活动类型" prop="actionType">
                  <el-select v-model="dataForm.actionType" placeholder="" disabled>
                    <el-option
                      v-for="item in actionType"
                      :key="item.dictKey"
                      :label="item.dictValue"
                      :value="item.dictKey">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <head-layout
              head-title='"零违章"人员信息'
            ></head-layout>
            <el-row class="custom-row" :gutter="20" style="margin-top: 20px">
              <el-col :span="12">
                <el-form-item label='"零违章"个人总数' prop="monthPeopleCnt">
                  <el-input
                    v-model="dataForm.monthPeopleCnt"
                    controls-position="right"
                    maxlength="8"
                    show-word-limit
                    :readonly="type == 'view' || dataForm.dataState == '2' || dataForm.dataState == '4'"
                    style="width: calc(100% - 30px)"
                  ></el-input>
                  <el-tooltip class="item" effect="dark" :content="dataForm.monthPeopleCntS" placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="其中一线员工数量" prop="monthFirstlinePeopleCnt">
                  <el-input
                    v-model="dataForm.monthFirstlinePeopleCnt"
                    controls-position="right"
                    maxlength="8"
                    show-word-limit
                    :readonly="type == 'view' || dataForm.dataState == '2' || dataForm.dataState == '4'"
                    style="width: calc(100% - 30px)"
                  ></el-input>
                  <el-tooltip class="item" effect="dark" :content="dataForm.monthFirstlinePeopleCntS" placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="本年累计人数" prop="yearPeopleCnt">
                  <el-input
                    v-model="yearPeopleCnt"
                    controls-position="right"
                    maxlength="8"
                    show-word-limit
                    :disabled="true"
                    style="width: calc(100% - 30px)"
                  ></el-input>
                  <el-tooltip class="item" effect="dark" :content="dataForm.yearPeopleCntS" placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
            </el-row>
            <head-layout
              head-title='"零违章"组织信息'
            ></head-layout>
            <el-row class="custom-row" :gutter="20" style="margin-top: 20px">
              <el-col :span="12">
                <el-form-item label='"零违章"班组数量' prop="monthGroupCnt">
                  <el-input
                    v-model="dataForm.monthGroupCnt"
                    controls-position="right"
                    maxlength="8"
                    show-word-limit
                    :readonly="type == 'view' || dataForm.dataState == '2' || dataForm.dataState == '4'"
                    style="width: calc(100% - 30px)"
                  ></el-input>
                  <el-tooltip class="item" effect="dark" :content="dataForm.monthGroupCntS" placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label='"零违章"工段数量' prop="monthWorkSectionCnt">
                  <el-input
                    v-model="dataForm.monthWorkSectionCnt"
                    controls-position="right"
                    maxlength="8"
                    show-word-limit
                    :readonly="type == 'view' || dataForm.dataState == '2' || dataForm.dataState == '4'"
                    style="width: calc(100% - 30px)"
                  ></el-input>
                  <el-tooltip class="item" effect="dark" :content="dataForm.monthWorkSectionCntS" placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label='"零违章"车间/部门数量' prop="monthWorkshopCnt">
                  <el-input
                    v-model="dataForm.monthWorkshopCnt"
                    controls-position="right"
                    maxlength="8"
                    show-word-limit
                    :readonly="type == 'view' || dataForm.dataState == '2' || dataForm.dataState == '4'"
                    style="width: calc(100% - 30px)"
                  ></el-input>
                  <el-tooltip class="item" effect="dark" :content="dataForm.monthWorkshopCntS" placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label='月度评选"零违章"组织数量' prop="monthOrgCnt">
                  <el-input
                    v-model="monthOrgCnt"
                    controls-position="right"
                    maxlength="8"
                    show-word-limit
                    :disabled="true"
                    style="width: calc(100% - 30px)"
                  ></el-input>
                  <el-tooltip class="item" effect="dark" :content="dataForm.monthOrgCntS" placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="本年累计组织数" prop="yearOrgCnt">
                  <el-input
                    v-model="yearOrgCnt"
                    controls-position="right"
                    maxlength="8"
                    show-word-limit
                    :disabled="true"
                    style="width: calc(100% - 30px)"
                  ></el-input>
                  <el-tooltip class="item" effect="dark" :content="dataForm.yearOrgCntS" placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-main>
      </div>
    </el-scrollbar>
  </div>
</template>
<script>
import {getDictionaryCode} from "@/api/system/dictbiz";

let baseUrl = '/api/sinoma-system/dict/dictionary?code='

import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {saveOrUpdate, detail, detailByBusiness} from "@/api/report/safetActionStat";
import {processList as getProcessList} from "@/api/plugin/workflow/process";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import draft from "@/views/plugin/workflow/mixins/draft";
import {mapGetters} from "vuex";

export default {
  name: "protocolEdit",
  mixins: [exForm, draft],
  components: {
    FormLayout,
    HeadLayout
  },
  data() {
    return {
      rules: {
        dateAndTime: [
          {required: true, message: '请选择填报年月', trigger: ['blur']},
        ],
        monthPeopleCnt: [
          {required: true, message: '请输入"零违章"个人总数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        monthFirstlinePeopleCnt: [
          {required: true, message: '请输入其中一线员工数量', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        // monthOrgCnt: [
        //   {required: true, message: '请输入本月组织数', trigger: ['blur']},
        //   {pattern: /^[0-9]+$/, message: '请输入正整数'}
        // ],
        monthGroupCnt: [
          {required: true, message: '请输入"零违章"班组数量', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        monthWorkSectionCnt: [
          {required: true, message: '请输入"零违章"工段数量', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        monthWorkshopCnt: [
          {required: true, message: '请输入"零违章"车间/部门数量', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        // yearPeopleCnt: [
        //   {required: true, message: '请输入本年累计人数', trigger: ['blur']},
        //   {pattern: /^[0-9]+$/, message: '请输入正整数'}
        // ],
        // yearOrgCnt: [
        //   {required: true, message: '请输入本年累计组织数', trigger: ['blur']},
        //   {pattern: /^[0-9]+$/, message: '请输入正整数'}
        // ],
      },
      actionType: '',
      existingYearPeopleCnt: 0,
      existingYearOrgCnt: 0,
      type: 'view',
      dataForm: {},
      disabled: false,
      formLoading: false,
      processTemplateKey: "report_fill_safet_action_stat",
      formProcess: {
        id: '',
        processId: '',
        paperName: '',
      },
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
    headBtnOptions() {
      let result = [];
      if (this.dataForm.dataState == 3) {
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            type: "button",
            icon: "",
            btnOptType: 'save',
          }
        );
      }
      return result;
    },
    yearPeopleCnt() {
      let number = 0;
      const monthPeopleCnt = this.dataForm.monthPeopleCnt && this.dataForm.monthPeopleCnt.toString().replace(/\D/g, '');
      const existingYearPeopleCnt = this.existingYearPeopleCnt && this.existingYearPeopleCnt.toString().replace(/\D/g, '');
      if (monthPeopleCnt || existingYearPeopleCnt) {
        number = (monthPeopleCnt ? Number(monthPeopleCnt) : 0)
          + (existingYearPeopleCnt ? Number(existingYearPeopleCnt) : 0);
      } else {
        number = 0;
      }
      this.dataForm.yearPeopleCnt = number;
      return number;
    },
    yearOrgCnt() {
      let number = 0;
      const monthOrgCnt = this.dataForm.monthOrgCnt && this.dataForm.monthOrgCnt.toString().replace(/\D/g, '');
      const existingYearOrgCnt = this.existingYearOrgCnt && this.existingYearOrgCnt.toString().replace(/\D/g, '');
      if (monthOrgCnt || existingYearOrgCnt) {
        number = (monthOrgCnt ? Number(monthOrgCnt) : 0)
          + (existingYearOrgCnt ? Number(existingYearOrgCnt) : 0);
      } else {
        number = 0;
      }
      this.dataForm.yearOrgCnt = number;
      return number;
    },
    monthOrgCnt() {
      let number = 0;
      const monthGroupCnt = this.dataForm.monthGroupCnt && this.dataForm.monthGroupCnt.toString().replace(/\D/g, '');
      const monthWorkSectionCnt = this.dataForm.monthWorkSectionCnt && this.dataForm.monthWorkSectionCnt.toString().replace(/\D/g, '');
      const monthWorkshopCnt = this.dataForm.monthWorkshopCnt && this.dataForm.monthWorkshopCnt.toString().replace(/\D/g, '');
      if (monthGroupCnt || monthWorkSectionCnt || monthWorkshopCnt) {
        number = (monthGroupCnt ? Number(monthGroupCnt) : 0)
          + (monthWorkSectionCnt ? Number(monthWorkSectionCnt) : 0)
          + (monthWorkshopCnt ? Number(monthWorkshopCnt) : 0);
      } else {
        number = 0;
      }
      this.dataForm.monthOrgCnt = number;
      return number;
    },
  },
  created() {
    this.getProcessId();
    this.getDictionary();
    let {unitName, unitCode, type, id} = this.$route.query;
    this.type = type;
    this.dataForm.companyName = unitName
    this.dataForm.unitCode = unitCode
    this.dataForm.id = id
    this.initData()
  },
  async mounted() {
    let flowData = await detail(this.dataForm.id);
    if (flowData.data.data.dataState != 3) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
    this.$forceUpdate();
  },
  methods: {
    getDictionary() {
      getDictionaryCode('action_type').then((res) => {
        this.actionType = res.data.data;
      })
    },
    chooseDate(e) {
      this.dataForm.dateAndTime = e
      if (e != null) {
        detailByBusiness(this.dataForm).then((res) => {
          const data = res.data.data
          if (data.id && data.id != -1) {
            this.dataForm = data
          } else {
            this.dataForm.id = null
            this.dataForm.dataState = null
            this.dataForm.processInsId = null
            this.dataForm.createName = null
            this.dataForm.updateName = null
          }
          if (data.existingYearPeopleCnt != -1) {
            this.existingYearPeopleCnt = data.existingYearPeopleCnt
          }
          if (data.existingYearOrgCnt != -1) {
            this.existingYearOrgCnt = data.existingYearOrgCnt
          }
        })
      }
    },
    initData() {
      detail(this.dataForm.id).then(res => {
        const item = res.data.data;
        if (item.dataState == -1) {
          item.dataState = null
        }
        item.safeWorkHours == -1 ? item.safeWorkHours = null : item.safeWorkHours
        item.safeInvest == -1 ? item.safeInvest = null : item.safeInvest
        item.trainTimes == -1 ? item.trainTimes = null : item.trainTimes
        item.trainPeopleCnt == -1 ? item.trainPeopleCnt = null : item.trainPeopleCnt
        item.checkCnt == -1 ? item.checkCnt = null : item.checkCnt
        item.hdCnt == -1 ? item.hdCnt = null : item.hdCnt
        item.rectifiedHdCnt == -1 ? item.rectifiedHdCnt = null : item.rectifiedHdCnt
        item.majorHDCnt == -1 ? item.majorHDCnt = null : item.majorHDCnt
        item.emergencDrillCnt == -1 ? item.emergencDrillCnt = null : item.emergencDrillCnt
        if (item.yearPeopleCntS != -1) {
          this.existingYearPeopleCnt = item.existingYearOrgCnt
        }
        if (item.yearOrgCntS != -1) {
          this.existingYearOrgCnt = item.existingYearOrgCnt
        }
        this.dataForm = item;
      })
    },
    headSave(cancel = false) {
      this.dataForm.dataSource = 'fill'
      if (!this.dataForm.id) {
        this.dataForm.dataState = 1
      }
      this.$refs.formLayout.validate((valid) => {
        if (valid) {
          this.$loading();
          saveOrUpdate(this.dataForm)
            .then(res => {
              const {msg, data} = res.data;
              this.dataForm = data
              this.$message({
                type: "success",
                message: "操作成功,数据已刷新"
              });
            }).finally(() => {
            this.$loading().close();
          })
        }
      })
    },
    headSubmit(row) {
      if (this.$refs.formLayout.dataForm.id) {
        this.$confirm("确定提交审核?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            var row = this.$refs.formLayout.dataForm;
            if (row.safeWorkHours == null || row.safeWorkHours < 0) {
              return this.$message({
                message: "请先进行填报",
                type: "warning",
              });
            }
            saveOrUpdate({
              ...this.$refs.formLayout.dataForm
            }).then((res) => {
              if (res.data.code === 200) {
                this.dataForm = res.data.data;
              }
            }).then(() => {
              // 页面全局Loading
              this.$loading();
              this.rpTaskAttributeSubmit(row);
            })
          }).finally(() => {
          this.$loading().close();
        })
      } else {
        this.$message.warning("请先保存数据");
      }
    },
    rpTaskAttributeSubmit(data) {
      this.formProcess.id = data.id;
      this.formProcess.deptId = this.nodeId
      this.handleStartProcess5(true,true).then((res) => {
        this.dataForm.processInsId = res.data.data;
        this.dataForm.dataState = 2;
        saveOrUpdate(this.dataForm).then((res) => {
          if (res.data.code === 200) {
            this.$emit("closeDialog")
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.initData();
          }
        })
          .catch((err) => {
          })
          .finally(() => {
            this.$loading().close();
          });
      });
    },
    // 获取流程Id
    getProcessId() {
      getProcessList(1, 100).then((res) => {
        let processList = res.data.data.records
        if (processList.length === 0) {
          this.$message({
            type: "warning",
            message: "流程列表为空，请联系管理员",
          });
          return;
        }
        let process = processList.filter(
          (item) => item.key === this.processTemplateKey
        )[0];
        if (process === undefined) {
          this.$message({
            type: "warning",
            message: "未查询到该流程，请联系管理员",
          });
          return;
        }
        this.submitLoading = false;
        this.tag.label = process.name;
        this.formProcess.processId = process.id;
      });
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-textarea__inner {
  height: 70vh;
}

::v-deep .el-form-item__content {
  display: flex;
}

.item {
  font-size: 24px;
  margin-left: 6px;
}

.iconLine {
  font-size: 24px;
  margin-left: 6px;
  padding: 4px 0;
}

.borderLine {
  width: 100%;
  border-top: 1px solid #DCDFE6;
  padding-top: 12px;
}
</style>
